<template>
  <b-card title="Echo" sub-title="Information about echo">
    <b-row>
      <b-col md="3">
        <b-row>
          <b-col cols="12">
            <b-form-group label="LVEF" label-for="lvef" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.preop_echo_LVEF"
                id="lvef"
                type="number"
                placeholder="LVEF"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="LVEDD" label-for="lvedd" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.preop_echo_LVEDD"
                id="lvedd"
                type="number"
                placeholder="LVEDD"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="RVEDD" label-for="rvedd" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.preop_echo_RVEDD"
                id="rvedd"
                type="number"
                placeholder="RVEDD"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="FAC RV" label-for="fac_rv" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.preop_echo_FAC_RV"
                id="fac_rv"
                type="number"
                placeholder="FAC RV"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="FAC LV" label-for="fac_lv" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.preop_echo_FAC_LV"
                id="fac_lv"
                type="number"
                placeholder="FAC LV"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="LVESVI" label-for="lvesvi" label-cols-md="4">
              <b-form-input
                v-model="patientInfo.preop_echo_LVESVI"
                id="lvesvi"
                type="number"
                placeholder="LVESVI"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="TEI index"
              label-for="tei_index"
              label-cols-md="4"
            >
              <b-form-input
                v-model="patientInfo.preop_echo_TEI_index"
                id="tei_index"
                type="number"
                placeholder="TEI index"
                :readonly="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="pHTN > 60mmHg"
              label-for="preop_echo_phtn_over_60_mmhg"
              label-cols-md="4"
            >
              <v-select
                v-model="patientInfo.preop_echo_phtn_over_60_mmhg"
                label="value"
                :options="options"
                :disabled="readOnly"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-card class="bordered-card" title="AV">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="AV aortic insuff"
                    label-for="av"
                    label-cols-md="4"
                  >
                    <v-select
                      v-model="patientInfo.av_aortic_insuff"
                      label="value"
                      :options="options"
                      :disabled="readOnly"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Grade"
                    label-for="av_aortic_insuff_grade"
                    label-cols-md="4"
                  >
                    <b-form-input
                      v-model="patientInfo.av_aortic_insuff_grade"
                      id="grade"
                      type="number"
                      placeholder="Grade"
                      :readonly="readOnly"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="3">
        <b-card class="bordered-card" title="MV">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="MR grade"
                label-for="mr_grade"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_mv_MR_grade"
                  id="mr_grade"
                  type="number"
                  placeholder="MR grade"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MV diameter"
                label-for="mv_diameter"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_mv_MV_diameter"
                  id="mv_diameter"
                  type="number"
                  placeholder="MV diameter"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR Pmax"
                label-for="mr_pmax"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_mv_MR_pmax"
                  id="mr_pmax"
                  type="number"
                  placeholder="MR Pmax"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MV Pmax"
                label-for="mv_pmax"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_mv_MV_pmax"
                  id="mv_pmax"
                  type="number"
                  placeholder="MV Pmax"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR peak velocity[ms]"
                label-for="mr_velocity"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_mv_MR_peak_velocity_ms"
                  id="mr_velocity"
                  type="number"
                  placeholder="MR peak velocity[ms]"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR vena contracta"
                label-for="mr_contracta"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_mv_MR_vena_contracta"
                  id="mr_contracta"
                  type="number"
                  placeholder="MR vena contracta"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR EROA"
                label-for="mr_eroa"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_mv_MR_EROA"
                  id="mr_eroa"
                  type="number"
                  placeholder="MR EROA"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR PISA"
                label-for="mr_pisa"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_mv_MR_PISA"
                  id="mr_pisa"
                  type="number"
                  placeholder="MR PISA"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="MR meangradient"
                label-for="mr_meangradient"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_mv_MR_meangradient"
                  id="mr_meangradient"
                  type="number"
                  placeholder="MR meangradient"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="TDIS velocity MR annulus"
                label-for="tdis"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_mv_TDIS_velocity_MR_annulus"
                  id="tdis"
                  type="number"
                  placeholder="TDIS velocity MR annulus"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card class="bordered-card" title="TV">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="TR grade"
                label-for="preop_echo_tv_tr_grade"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_tv_tr_grade"
                  id="preop_echo_tv_tr_grade"
                  type="number"
                  placeholder="TR grade"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="PA pressure systolic"
                label-for="pa_pressure"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_tv_PA_pressure_systolic"
                  id="pa_pressure"
                  type="number"
                  placeholder="PA pressure systolic"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="VCL diameter[cm]"
                label-for="vcl_d"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_tv_VCL_diameter_cm"
                  id="vcl_d"
                  type="number"
                  placeholder="VCL diameter[cm]"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="TAPSE" label-for="tapse" label-cols-md="4">
                <b-form-input
                  v-model="patientInfo.preop_echo_tv_TAPSE"
                  id="tapse"
                  type="number"
                  placeholder="TAPSE"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="TR max" label-for="tr_max" label-cols-md="4">
                <b-form-input
                  v-model="patientInfo.preop_echo_tv_TR_vmax"
                  id="tr_max"
                  type="number"
                  placeholder="TR max"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="TR peak velocity"
                label-for="tr_velocity"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_tv_TR_peak_velocity_ms"
                  id="tr_velocity"
                  type="number"
                  placeholder="TR peak velocity"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="TDIS velocity TR annulus"
                label-for="tdis"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_tv_TDIS_velocity_TR_annulus"
                  id="tdis"
                  type="number"
                  placeholder="TDIS velocity TR annulus"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Stroke work index"
                label-for="stroke_work"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_tv_stroke_work_index"
                  id="stroke_work"
                  type="number"
                  placeholder="Stroke work index"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Tricusp annulus diameter"
                label-for="tricusp"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_tv_tricusp_annulus_diameter"
                  id="tricusp"
                  type="number"
                  placeholder="Tricusp annulus diameter"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="3">
        <b-card class="bordered-card" title="Svan Ganz">
          <b-row>
            <b-col cols="12">
              <b-form-group label="CVP RA" label-for="cvp_ra" label-cols-md="4">
                <b-form-input
                  v-model="patientInfo.preop_echo_svan_ganz_CVP_RA"
                  id="cvp_ra"
                  type="number"
                  placeholder="CVP RA"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="PCWP" label-for="pcwp" label-cols-md="4">
                <b-form-input
                  v-model="patientInfo.preop_echo_svan_ganz_PCWP"
                  id="pcwp"
                  type="number"
                  placeholder="PCWP"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="PA systolic pressure"
                label-for="pa_systolic"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_svan_ganz_PA_systolic_p"
                  id="pa_systolic"
                  type="number"
                  placeholder="PA systolic pressure"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="PA diastolic pressure"
                label-for="pa_diastolic"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_svan_ganz_PA_diastolic_p"
                  id="pa_diastolic"
                  type="number"
                  placeholder="PA diastolic pressure"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="PA mean"
                label-for="pa_mean"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_svan_ganz_PA_mean"
                  id="pa_mean"
                  type="number"
                  placeholder="PA mean"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="SVR" label-for="svr" label-cols-md="4">
                <b-form-input
                  v-model="patientInfo.preop_echo_svan_ganz_SVR"
                  id="svr"
                  type="number"
                  placeholder="SVR"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="PVR" label-for="pvr" label-cols-md="4">
                <b-form-input
                  v-model="patientInfo.preop_echo_svan_ganz_PVR"
                  id="pvr"
                  type="number"
                  placeholder="PVR"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Stroke volume"
                label-for="stroke_volume"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_svan_ganz_stroke_volume"
                  id="stroke_volume"
                  type="number"
                  placeholder="Stroke volume"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Heart rate"
                label-for="heart_rate"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_svan_ganz_heart_rate"
                  id="heart_rate"
                  type="number"
                  placeholder="Heart rate"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Cardiac index"
                label-for="cardiac_index"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_echo_svan_ganz_cardiac_index"
                  id="cardiac_index"
                  type="number"
                  placeholder="Cardiac index"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapState } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        preop_echo_FAC_LV: 0,
        preop_echo_LVEF: 0,
        preop_echo_LVEDD: 0,
        preop_echo_RVEDD: 0,
        preop_echo_FAC_RV: 0,
        preop_echo_LVESVI: 0,
        preop_echo_TEI_index: 0,
        av_aortic_insuff: "",
        av_aortic_insuff_grade: 0,
        preop_echo_phtn_over_60_mmhg: "",
        preop_echo_tv_tr_grade: 0,
        preop_echo_tv_PA_pressure_systolic: 0,
        preop_echo_tv_VCL_diameter_cm: 0,
        preop_echo_tv_TAPSE: 0,
        preop_echo_tv_TR_vmax: 0,
        preop_echo_tv_TR_peak_velocity_ms: 0,
        preop_echo_tv_TDIS_velocity_TR_annulus: 0,
        preop_echo_tv_stroke_work_index: 0,
        preop_echo_tv_tricusp_annulus_diameter: 0,
        preop_echo_mv_MR_grade: 0,
        preop_echo_mv_MV_diameter: 0,
        preop_echo_mv_MR_pmax: 0,
        preop_echo_mv_MV_pmax: 0,
        preop_echo_mv_MR_peak_velocity_ms: 0,
        preop_echo_mv_MR_vena_contracta: 0,
        preop_echo_mv_MR_EROA: 0,
        preop_echo_mv_MR_PISA: 0,
        preop_echo_mv_MR_meangradient: 0,
        preop_echo_mv_TDIS_velocity_MR_annulus: 0,
        preop_echo_svan_ganz_CVP_RA: 0,
        preop_echo_svan_ganz_PCWP: 0,
        preop_echo_svan_ganz_PA_systolic_p: 0,
        preop_echo_svan_ganz_PA_diastolic_p: 0,
        preop_echo_svan_ganz_PA_mean: 0,
        preop_echo_svan_ganz_SVR: 0,
        preop_echo_svan_ganz_PVR: 0,
        preop_echo_svan_ganz_stroke_volume: 0,
        preop_echo_svan_ganz_heart_rate: 0,
        preop_echo_svan_ganz_cardiac_index: 0,
      },
      options: ["yes", "no"],
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    setPatientInfo(v) {
      this.patientInfo._id = v._id;
      this.patientInfo.preop_echo_FAC_LV = v.preop_echo_FAC_LV;
      this.patientInfo.preop_echo_LVEF = v.preop_echo_LVEF;
      this.patientInfo.preop_echo_LVEDD = v.preop_echo_LVEDD;
      this.patientInfo.preop_echo_RVEDD = v.preop_echo_RVEDD;
      this.patientInfo.preop_echo_FAC_RV = v.preop_echo_FAC_RV;
      this.patientInfo.preop_echo_LVESVI = v.preop_echo_LVESVI;
      this.patientInfo.preop_echo_TEI_index = v.preop_echo_TEI_index;
      this.patientInfo.av_aortic_insuff = v.av_aortic_insuff;
      this.patientInfo.av_aortic_insuff_grade = v.av_aortic_insuff_grade;
      this.patientInfo.preop_echo_phtn_over_60_mmhg =
        v.preop_echo_phtn_over_60_mmhg;
      this.patientInfo.preop_echo_tv_tr_grade = v.preop_echo_tv_tr_grade;
      this.patientInfo.preop_echo_tv_PA_pressure_systolic =
        v.preop_echo_tv_PA_pressure_systolic;
      this.patientInfo.preop_echo_tv_VCL_diameter_cm =
        v.preop_echo_tv_VCL_diameter_cm;
      this.patientInfo.preop_echo_tv_TAPSE = v.preop_echo_tv_TAPSE;
      this.patientInfo.preop_echo_tv_TR_vmax = v.preop_echo_tv_TR_vmax;
      this.patientInfo.preop_echo_tv_TR_peak_velocity_ms =
        v.preop_echo_tv_TR_peak_velocity_ms;
      this.patientInfo.preop_echo_tv_TDIS_velocity_TR_annulus =
        v.preop_echo_tv_TDIS_velocity_TR_annulus;
      this.patientInfo.preop_echo_tv_stroke_work_index =
        v.preop_echo_tv_stroke_work_index;
      this.patientInfo.preop_echo_tv_tricusp_annulus_diameter =
        v.preop_echo_tv_tricusp_annulus_diameter;
      this.patientInfo.preop_echo_mv_MR_grade = v.preop_echo_mv_MR_grade;
      this.patientInfo.preop_echo_mv_MV_diameter = v.preop_echo_mv_MV_diameter;
      this.patientInfo.preop_echo_mv_MR_pmax = v.preop_echo_mv_MR_pmax;
      this.patientInfo.preop_echo_mv_MV_pmax = v.preop_echo_mv_MV_pmax;
      this.patientInfo.preop_echo_mv_MR_peak_velocity_ms =
        v.preop_echo_mv_MR_peak_velocity_ms;
      this.patientInfo.preop_echo_mv_MR_vena_contracta =
        v.preop_echo_mv_MR_vena_contracta;
      this.patientInfo.preop_echo_mv_MR_EROA = v.preop_echo_mv_MR_EROA;
      this.patientInfo.preop_echo_mv_MR_PISA = v.preop_echo_mv_MR_PISA;
      this.patientInfo.preop_echo_mv_MR_meangradient =
        v.preop_echo_mv_MR_meangradient;
      this.patientInfo.preop_echo_mv_TDIS_velocity_MR_annulus =
        v.preop_echo_mv_TDIS_velocity_MR_annulus;
      this.patientInfo.preop_echo_svan_ganz_CVP_RA =
        v.preop_echo_svan_ganz_CVP_RA;
      this.patientInfo.preop_echo_svan_ganz_PCWP = v.preop_echo_svan_ganz_PCWP;
      this.patientInfo.preop_echo_svan_ganz_PA_systolic_p =
        v.preop_echo_svan_ganz_PA_systolic_p;
      this.patientInfo.preop_echo_svan_ganz_PA_diastolic_p =
        v.preop_echo_svan_ganz_PA_diastolic_p;
      this.patientInfo.preop_echo_svan_ganz_PA_mean =
        v.preop_echo_svan_ganz_PA_mean;
      this.patientInfo.preop_echo_svan_ganz_SVR = v.preop_echo_svan_ganz_SVR;
      this.patientInfo.preop_echo_svan_ganz_PVR = v.preop_echo_svan_ganz_PVR;
      this.patientInfo.preop_echo_svan_ganz_stroke_volume =
        v.preop_echo_svan_ganz_stroke_volume;
      this.patientInfo.preop_echo_svan_ganz_heart_rate =
        v.preop_echo_svan_ganz_heart_rate;
      this.patientInfo.preop_echo_svan_ganz_cardiac_index =
        v.preop_echo_svan_ganz_cardiac_index;
    },
  },
};
</script>

<style>
</style>
