<template>
    <b-card title="Laboratory" sub-title="Information about laboratory">
      <b-row>
        <b-col cols="8">
          <b-form-group
            label="Creatinin"
            label-for="creatinin"
            label-cols-md="4"
          >
            <b-form-input
              v-model="patientInfo.preop_lab_creatinin"
              id="creatinin"
              type="number"
              placeholder="Creatinin"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label="GFR"
            label-for="gfr"
            label-cols-md="4"
          >
            <b-form-input
              v-model="patientInfo.preop_lab_GFR"
              id="gfr"
              type="number"
              placeholder="GFR"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label="NT pro BNP"
            label-for="nt_pro"
            label-cols-md="4"
          >
            <b-form-input
              v-model="patientInfo.preop_lab_NT_proBNP"
              id="nt_pro"
              type="number"
              placeholder="NT pro BNP"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label="gammaGT"
            label-for="gamma_gt"
            label-cols-md="4"
          >
            <b-form-input
              v-model="patientInfo.preop_lab_gammaGT"
              id="gamma_gt"
              type="number"
              placeholder="gammaGT"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label="GOT"
            label-for="got"
            label-cols-md="4"
          >
            <b-form-input
              v-model="patientInfo.preop_lab_GOT"
              id="got"
              type="number"
              placeholder="GOT"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label="HB"
            label-for="hb"
            label-cols-md="4"
          >
            <b-form-input
              v-model="patientInfo.preop_lab_hb"
              id="hb"
              type="number"
              placeholder="HB"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label="Troponin"
            label-for="troponin"
            label-cols-md="4"
          >
            <b-form-input
              v-model="patientInfo.preop_lab_troponin"
              id="troponin"
              type="number"
              placeholder="Troponin"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label="Bilirubin"
            label-for="bilirubin"
            label-cols-md="4"
          >
            <b-form-input
              v-model="patientInfo.preop_lab_bilirubin"
              id="bilirubin"
              type="number"
              placeholder="Bilirubin"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label="Albumin"
            label-for="albumin"
            label-cols-md="4"
          >
            <b-form-input
              v-model="patientInfo.preop_lab_albumin"
              id="albumin"
              type="number"
              placeholder="Albumin"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label="INR"
            label-for="inr"
            label-cols-md="4"
          >
            <b-form-input
              v-model="patientInfo.preop_lab_inr"
              id="inr"
              type="number"
              placeholder="INR"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapState } from 'vuex'

export default {
  components: {
    BRow, BCol, BCard, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BFormDatepicker,
    vSelect
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        preop_lab_creatinin: 0,
        preop_lab_GFR: 0,
        preop_lab_NT_proBNP: 0,
        preop_lab_gammaGT: 0,
        preop_lab_GOT: 0,
        preop_lab_hb: 0,
        preop_lab_troponin: 0,
        preop_lab_bilirubin: 0,
        preop_lab_albumin: 0,
        preop_lab_inr: 0
      }
    }
  },
  computed: {
    ...mapState('patient', ['patient', 'role']),
    readOnly() {
      return this.role == 'viewer' || this.role == ''
    },
  },
  watch: {
		patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v)
      }
		},
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient)
    }
  },
  methods: {
    setPatientInfo(v) {
      this.patientInfo._id = v._id
      this.patientInfo.preop_lab_creatinin = v.preop_lab_creatinin
      this.patientInfo.preop_lab_GFR = v.preop_lab_GFR
      this.patientInfo.preop_lab_NT_proBNP = v.preop_lab_NT_proBNP
      this.patientInfo.preop_lab_gammaGT = v.preop_lab_gammaGT
      this.patientInfo.preop_lab_GOT = v.preop_lab_GOT
      this.patientInfo.preop_lab_hb = v.preop_lab_hb
      this.patientInfo.preop_lab_troponin = v.preop_lab_troponin
      this.patientInfo.preop_lab_bilirubin = v.preop_lab_bilirubin
      this.patientInfo.preop_lab_albumin = v.preop_lab_albumin
      this.patientInfo.preop_lab_inr = v.preop_lab_inr
    }
  }
}
</script>

<style>

</style>
