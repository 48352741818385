<template>
  <b-card title="Previous" sub-title="Information about previous">
    <b-row>
      <b-col md="6">
        <b-card class="bordered-card" title="Previous Cardiac Surgery">
          <b-row>
            <b-col cols="12">
              <b-form-group label="---" label-for="---" label-cols-md="4">
                <v-select
                  label="value"
                  :options="options"
                  v-model="patientInfo.previous_cardiac_surgery"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Date" label-for="v-birth" label-cols-md="4">
                <b-input-group class="mb-1">
                  <b-form-input
                    id="example-input"
                    ref="previous_cardiac_surgery_date"
                    v-model="patientInfo.previous_cardiac_surgery_date"
                    type="text"
                    placeholder="DD.MM.YYYY"
                    v-mask="dateMask"
                    autocomplete="off"
                    show-decade-nav
                    :readonly="readOnly"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="date1"
                      value-as-date
                      show-decade-nav
                      button-only
                      button-variant="outline-primary"
                      right
                      size="sm"
                      locale="de"
                      aria-controls="example-input"
                      :disabled="readOnly"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Type" label-for="type" label-cols-md="4">
                <b-form-input
                  id="type"
                  type="text"
                  placeholder="Type"
                  v-model="patientInfo.previous_cardiac_surgery_type"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Interval [d]:"
                label-for="interval"
                label-cols-md="4"
              >
                <div class="vertical-align">
                  {{ patientInfo.previous_cardiac_surgery_interval }}
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card class="bordered-card" title="Previous Cardiac Intervention">
          <b-row>
            <b-col cols="12">
              <b-form-group label="---" label-for="---" label-cols-md="4">
                <v-select
                  v-model="patientInfo.previous_cardiac_intervention"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-2">
              <b-form-group
                label="Mitraclip"
                label-for="mitraclip"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.previous_cardiac_intervention_mitraclip"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Date" label-for="v-birth" label-cols-md="4">
                <b-input-group class="mb-1">
                  <b-form-input
                    id="example-input"
                    ref="previous_cardiac_intervention_mitraclip_date"
                    v-model="
                      patientInfo.previous_cardiac_intervention_mitraclip_date
                    "
                    type="text"
                    placeholder="DD.MM.YYYY"
                    v-mask="dateMask"
                    autocomplete="off"
                    show-decade-nav
                    :readonly="readOnly"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="date2"
                      value-as-date
                      show-decade-nav
                      button-only
                      button-variant="outline-primary"
                      right
                      size="sm"
                      locale="de"
                      aria-controls="example-input"
                      :disabled="readOnly"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Interval [d]:"
                label-for="interval"
                label-cols-md="4"
              >
                <div class="vertical-align">
                  {{
                    patientInfo.previous_cardiac_intervention_mitraclip_interval
                  }}
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mt-2">
              <b-form-group
                label="Triclip"
                label-for="triclip"
                label-cols-md="4"
              >
                <v-select
                  v-model="patientInfo.previous_cardiac_intervention_tripclip"
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Date" label-for="v-birth" label-cols-md="4">
                <b-input-group class="mb-1">
                  <b-form-input
                    id="example-input"
                    ref="previous_cardiac_intervention_tripclip_date"
                    v-model="
                      patientInfo.previous_cardiac_intervention_tripclip_date
                    "
                    type="text"
                    placeholder="DD.MM.YYYY"
                    v-mask="dateMask"
                    autocomplete="off"
                    show-decade-nav
                    :readonly="readOnly"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="date3"
                      value-as-date
                      show-decade-nav
                      button-only
                      button-variant="outline-primary"
                      right
                      size="sm"
                      locale="de"
                      aria-controls="example-input"
                      :disabled="readOnly"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Interval [d]:"
                label-for="interval"
                label-cols-md="4"
              >
                <div class="vertical-align">
                  {{
                    patientInfo.previous_cardiac_intervention_triclip_interval
                  }}
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { convertDate2Str, dateMask, subtractDate } from "@core/utils/utils";
import { mapState } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        previous_cardiac_surgery: "",
        previous_cardiac_surgery_date: "",
        previous_cardiac_surgery_type: "",
        previous_cardiac_surgery_interval: 0,
        previous_cardiac_intervention: "",
        previous_cardiac_intervention_date: "",
        previous_cardiac_intervention_mitraclip: "",
        previous_cardiac_intervention_mitraclip_date: "",
        previous_cardiac_intervention_mitraclip_interval: 0,
        previous_cardiac_intervention_tripclip: "",
        previous_cardiac_intervention_tripclip_date: "",
        previous_cardiac_intervention_triclip_interval: 0,
      },
      value: "",
      options: ["yes", "no"],
      v1: "",
      v2: "",
      v3: "",
      date1: null,
      date2: null,
      date3: null,
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
    previous_cardiac_surgery_interval() {
      return subtractDate(
        this.patientInfo.previous_cardiac_surgery_date,
        this.patient.procedure_date,
        "day"
      );
    },
    previous_cardiac_intervention_mitraclip_interval() {
      return subtractDate(
        this.patientInfo.previous_cardiac_intervention_mitraclip_date,
        this.patient.procedure_date,
        "day"
      );
    },
    previous_cardiac_intervention_triclip_interval() {
      return subtractDate(
        this.patientInfo.previous_cardiac_intervention_tripclip_date,
        this.patient.procedure_date,
        "day"
      );
    },
  },
  watch: {
    date1(v, ov) {
      if (v) {
        this.patientInfo.previous_cardiac_surgery_date = convertDate2Str(v);
      }
    },
    date2(v, ov) {
      if (v) {
        this.patientInfo.previous_cardiac_intervention_mitraclip_date =
          convertDate2Str(v);
      }
    },
    date3(v, ov) {
      if (v) {
        this.patientInfo.previous_cardiac_intervention_tripclip_date =
          convertDate2Str(v);
      }
    },
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
    previous_cardiac_surgery_interval(v, ov) {
      this.patientInfo.previous_cardiac_surgery_interval = v;
    },
    previous_cardiac_intervention_mitraclip_interval(v, ov) {
      this.patientInfo.previous_cardiac_intervention_mitraclip_interval = v;
    },
    previous_cardiac_intervention_triclip_interval(v, ov) {
      this.patientInfo.previous_cardiac_intervention_triclip_interval = v;
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;
      this.patientInfo.previous_cardiac_surgery = v.previous_cardiac_surgery;

      if (
        document.activeElement != this.$refs.previous_cardiac_surgery_date.$el
      ) {
        this.patientInfo.previous_cardiac_surgery_date =
          v.previous_cardiac_surgery_date;
      }

      this.patientInfo.previous_cardiac_surgery_type =
        v.previous_cardiac_surgery_type;
      this.patientInfo.previous_cardiac_surgery_interval =
        v.previous_cardiac_surgery_interval;
      this.patientInfo.previous_cardiac_intervention =
        v.previous_cardiac_intervention;
      this.patientInfo.previous_cardiac_intervention_date =
        v.previous_cardiac_intervention_date;
      this.patientInfo.previous_cardiac_intervention_mitraclip =
        v.previous_cardiac_intervention_mitraclip;

      if (
        document.activeElement !=
        this.$refs.previous_cardiac_intervention_mitraclip_date.$el
      ) {
        this.patientInfo.previous_cardiac_intervention_mitraclip_date =
          v.previous_cardiac_intervention_mitraclip_date;
      }

      this.patientInfo.previous_cardiac_intervention_mitraclip_interval =
        v.previous_cardiac_intervention_mitraclip_interval;
      this.patientInfo.previous_cardiac_intervention_tripclip =
        v.previous_cardiac_intervention_tripclip;

      if (
        document.activeElement !=
        this.$refs.previous_cardiac_intervention_tripclip_date.$el
      ) {
        this.patientInfo.previous_cardiac_intervention_tripclip_date =
          v.previous_cardiac_intervention_tripclip_date;
      }

      this.patientInfo.previous_cardiac_intervention_triclip_interval =
        v.previous_cardiac_intervention_triclip_interval;
    },
  },
};
</script>

<style>
</style>
