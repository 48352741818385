<template>
    <b-card title="Clinical" sub-title="Information about clinical">
      <b-row>
        <b-col cols="8">
          <b-form-group
            label="ASA classification"
            label-for="asa_class"
            label-cols-md="4"
          >
            <b-form-input
              v-model="patientInfo.status_preop_clinical_ASA_classification"
              id="asa_class"
              type="number"
              placeholder="ASA classification"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label="CCS angina pectoris"
            label-for="ccs_angina"
            label-cols-md="4"
          >
            <b-form-input
              v-model="patientInfo.status_preop_clinical_CCS_angina_pectoris"
              id="ccs_angina"
              type="number"
              placeholder="CCS angina pectoris"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        
        <b-col cols="8">
          <b-form-group
              label="NYHA"
              label-for="status_preop_clinical_nyha"
              label-cols-md="4"
          >
              <v-select
                  v-model="patientInfo.status_preop_clinical_nyha"
                  label="value"
                  :options="options1"
                  :disabled="readOnly"
              />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label="6 min walk test"
            label-for="walk_test"
            label-cols-md="4"
          >
            <b-form-input
              v-model="patientInfo.status_preop_clinical_6_min_walk_test"
              id="walk_test"
              type="number"
              placeholder="6 min walk test"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label="Frailty score"
            label-for="frailty_score"
            label-cols-md="4"
          >
            <b-form-input
              v-model="patientInfo.status_preop_clinical_frailty_score"
              id="frailty_score"
              type="number"
              placeholder="Frailty score"
              :readonly="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8" class="mt-2">
          <b-form-group
            label="Renal failure"
            label-for="status_preop_clinical_renal_failure"
            label-cols-md="4"
          >
            <v-select
              v-model="patientInfo.status_preop_clinical_renal_failure"
              label="value"
              :options="options"
              :disabled="readOnly"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label="Grade"
            label-for="status_preop_clinical_gfr"
            label-cols-md="4"
          >
            <v-select
              v-model="patientInfo.status_preop_clinical_gfr"
              label="value"
              :options="options2"
              :disabled="readOnly"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapState } from 'vuex'

export default {
  components: {
    BRow, BCol, BCard, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BFormDatepicker,
    vSelect
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        status_preop_clinical_ASA_classification: 0,
        status_preop_clinical_CCS_angina_pectoris: 0,
        status_preop_clinical_nyha: 0,
        status_preop_clinical_6_min_walk_test: 0,
        status_preop_clinical_frailty_score: 0,
        status_preop_clinical_renal_failure: 0,
        status_preop_clinical_gfr: 0
      },
      options: ['yes', 'no'],
      options1: ['I', 'II', 'III', 'IV'],
      options2: ['1 GFR: >90ml/min/1,73m^2', '2 GFR: 60-89ml/min/1,73m^2', '3 GFR: 30-59ml/min/1,73m^2', '4 GFR: 15-29ml/min/1,73m^2', '5 GFR: <15ml/min/1,73m^2']
    }
  },
  computed: {
    ...mapState('patient', ['patient', 'role']),
    readOnly() {
      return this.role == 'viewer' || this.role == ''
    },
  },
  watch: {
		patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v)
      }
		},
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient)
    }
  },
  methods: {
    setPatientInfo(v) {
      this.patientInfo._id = v._id
      this.patientInfo.status_preop_clinical_ASA_classification = v.status_preop_clinical_ASA_classification
      this.patientInfo.status_preop_clinical_CCS_angina_pectoris = v.status_preop_clinical_CCS_angina_pectoris
      this.patientInfo.status_preop_clinical_nyha = v.status_preop_clinical_nyha
      this.patientInfo.status_preop_clinical_6_min_walk_test = v.status_preop_clinical_6_min_walk_test
      this.patientInfo.status_preop_clinical_frailty_score = v.status_preop_clinical_frailty_score
      this.patientInfo.status_preop_clinical_renal_failure = v.status_preop_clinical_renal_failure
      this.patientInfo.status_preop_clinical_gfr = v.status_preop_clinical_gfr
    }
  }
}
</script>

<style>

</style>
